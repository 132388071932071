import React from "react"
import Layout from "../components/layout"

import Seo from "../components/seo"

import SignUp from '../components/sign-up'

const Newsletter = () => (
    <Layout>
        <Seo title="Newsletter" />
        <div>
            <SignUp />
        </div>
    </Layout>
)

export default Newsletter
import React, { Component } from "react"
import { Form, Button, Card } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";
import PropTypes from 'prop-types';

class SignUp extends Component {
    state = {
        captchaCompleted: false
    };

    onCaptchaCompleted = (value) => {
        //console.log(value)
        //this.setState(previousState => ({ captchaCompleted: true }));
    }

    render() {
        return (
            <Card className="text-center">
                <Card.Header>
                    <h3>
                        <strong>
                            <span role="img" aria-label="emoji">📫</span> Subscribe to the newsletter <span role="img" aria-label="emoji">📫</span>
                        </strong>
                    </h3>
                </Card.Header>
                <Card.Body>
                    <Card.Title>Receive exclusive news, games release date reminders and more!</Card.Title>
                    <Form action="https://newsletter.mxtgames.com/subscribe" method="POST" acceptCharset="utf-8" style={{ marginBottom: "0px" }}>
                        <Form.Row className="justify-content-center">
                            {/*<Form.Group controlId="name">
                                <Form.Control type="text" placeholder="Your name" name="name" />
                            </Form.Group>*/}
                            <Form.Group controlId="email">
                                <Form.Control type="email" placeholder="Your email" name="email" required />
                            </Form.Group>
                            <Form.Group controlId="hp" style={{ display: 'none' }}>
                                <Form.Label>HP</Form.Label>
                                <Form.Control type="text" name="hp" />
                            </Form.Group>
                            <Form.Control type="hidden" name="list" value="N8KKKpKbFcoKAwUy763UOXAw" />
                            <Form.Control type="hidden" name="subform" value="yes" />
                            <Form.Group controlId="join-button">
                                <Button variant="primary" type="submit" name="submit" id="submit">Sign Up</Button>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="justify-content-center">
                            {!this.state.captchaCompleted &&
                                <Form.Group controlId="captcha">
                                    <ReCAPTCHA
                                        sitekey="6LcIKa8ZAAAAACSTI_DbfxapxRXSldsFBbQvjqsP"
                                        onChange={this.onCaptchaCompleted}
                                    />
                                </Form.Group>}
                        </Form.Row>
                    </Form>
                </Card.Body>
            </Card>
        );
    }
}

SignUp.propTypes = {
    children: PropTypes.node
};

SignUp.defaultProps = {
    children: null
};

export default SignUp